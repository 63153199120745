import React from 'react';
import { Popover, Text } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { classes } from './PrivateProfileHint.st.css';
import { GROUP_WRAPPER } from '../dataHooks';
import classnames from 'classnames';

interface PrivateProfileHintProps {
  className?: string;
  children(props: { showPrivateProfileHint(): void }): React.ReactNode;
}

export const PrivateProfileHint: React.FC<PrivateProfileHintProps> = (
  props,
) => {
  const [shownPrivateProfileHint, setShownPrivateProfileHint] =
    React.useState<boolean>(false);
  // document object is not available in ssr, so set it after did mount
  const [isMounted, setIsMounted] = React.useState<boolean>(false);

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  const { t } = useTranslation();

  const popoverElement = props.children({ showPrivateProfileHint });

  if (!isMounted) {
    return <>{popoverElement}</>;
  }

  // appendTo can be set only if no ssr, because document object is not available there.
  return (
    <Popover
      shown={shownPrivateProfileHint}
      onClickOutside={() => {
        setShownPrivateProfileHint(false);
      }}
      disableClickOutsideWhenClosed={true}
      placement="top"
      wiredToSiteColors={false}
      appendTo={
        document.querySelector(`[data-hook="${GROUP_WRAPPER}"]`) || 'parent'
      }
      className={props.className}
      zIndex={2147483647}
    >
      <Popover.Element>{popoverElement}</Popover.Element>
      <Popover.Content>
        <Text className={classnames(classes.text)}>
          {t('groups-web.private-profile-hint.description')}
        </Text>
      </Popover.Content>
    </Popover>
  );

  function showPrivateProfileHint() {
    setShownPrivateProfileHint((currentShownValue) => {
      return !currentShownValue;
    });
  }
};
