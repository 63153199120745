import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { ButtonPriority } from 'wix-ui-tpa';

import { FeedItemSkeleton } from 'FeedWidget/Widget/Feed/FeedItem';
import { feedSelector } from 'FeedWidget/Widget/Feed/selectors';
import settingsParams from 'FeedWidget/settingsParams';
import { IControllerActions } from 'FeedWidget/types';
import { Button } from 'common/components/Button';

export interface FooterProps extends IControllerActions {}

export const Footer: React.FC<FooterProps> = (props) => {
  const { t } = useTranslation();
  const settings = useSettings();
  const { nextCursor } = useSelector(feedSelector);

  const limit = settings.get(settingsParams.postsCount);

  if (limit) {
    return (
      <Button
        priority={ButtonPriority.secondary}
        onClick={() => props.methods.group.redirectToGroup()}
      >
        {t('groups-web.feed.show-more.label')}
      </Button>
    );
  }

  return nextCursor ? (
    <>
      <FeedItemSkeleton />
      <FeedItemSkeleton />
    </>
  ) : null;
};
