import React from 'react';
import { DraftContent } from 'ricos-content';
import { truncateContent } from 'ricos-content/libs/truncateContent';
import { WixRicosViewer } from '@wix/ricos-viewer';
import { extractUoUParams } from '@wix/ricos-common/libs/extractUoUParams';
import { useEnvironment, useExperiments, useBi } from '@wix/yoshi-flow-editor';

import { viewerPluginSelector } from './plugins/viewer-plugins';
import { PRESETS, RichContentViewerProps } from './types';

import { classes, st } from './ricos.st.css';
import { useHelpers } from './hooks/useHelpers';
import { useRicosTheme } from './useTheme';
import { AppDataContext } from '../../contexts/AppData/AppData';
import { HostContext } from '../../contexts/AppSettings/HostContext';
import { SeeMore } from '../../SeeMore';

function truncate(content: DraftContent) {
  return truncateContent(content, {
    /**
     * Web have more space, so `wordsCount` and `blocksCount` limits increased compared to mobile:
     * https://github.com/wix-private/wix-one-groups/blob/a4398d31e2607fce67e82613b808766512020f3c/src/feed/components/rich-content-viewer.tsx#L19
     */
    wordsCount: 150,
    maxPlugins: 2,
    blocksCount: 6,
  });
}

export const RichContentViewer: React.FC<RichContentViewerProps> = (props) => {
  const { preset, usage, className, contentId, showSeeMore } = props;
  const { experiments } = useExperiments();
  const { language } = useEnvironment();
  const biLogger = useBi();

  const { instance } = React.useContext(AppDataContext);

  const host = React.useContext(HostContext);
  const ricosThemeProps = useRicosTheme(host!);

  const helpers = useHelpers();
  const pluginSelector = viewerPluginSelector(preset!, helpers);

  const [shouldTruncateContent, setShouldTruncateContent] =
    React.useState(true);

  const defaultBIParams = extractUoUParams({ biLogger });

  const truncatedContent = React.useMemo(() => {
    if (showSeeMore && props.content) {
      return truncate(props.content);
    }

    return {
      content: props.content,
      isTruncated: false,
    };
  }, [truncate, showSeeMore, props.content]);

  // TODO: move SeeMore functionality inside RicosViewer
  const ricosContent = React.useMemo(() => {
    if (showSeeMore && truncatedContent?.isTruncated && shouldTruncateContent) {
      return truncatedContent?.content;
    }

    return props.content;
  }, [truncatedContent, shouldTruncateContent, showSeeMore, props.content]);

  const handleTruncateLabelClick = React.useCallback(() => {
    setShouldTruncateContent(!shouldTruncateContent);
  }, [setShouldTruncateContent, shouldTruncateContent]);

  return (
    <div
      className={st(classes.root, { mobile: props.isMobile } as any, className)}
    >
      <WixRicosViewer
        {...ricosThemeProps}
        {...props}
        content={ricosContent}
        instance={instance}
        plugins={pluginSelector.plugins}
        locale={language}
        linkSettings={{
          rel: { nofollow: true, ugc: true },
          anchorTarget: '_blank',
        }}
        wixExperiments={experiments}
        biSettings={{
          consumer: 'Groups',
          platform: 'Livesite',
          contentId,
          usage,
          defaultParams: defaultBIParams,
        }}
      />

      {showSeeMore && truncatedContent.isTruncated && (
        <SeeMore
          onClick={handleTruncateLabelClick}
          shouldTruncateContent={shouldTruncateContent}
        />
      )}
    </div>
  );
};

RichContentViewer.displayName = 'RichContentViewer';
RichContentViewer.defaultProps = {
  preset: PRESETS.VIEWER,
  disablePreview: false,
};
