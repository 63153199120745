import React from 'react';
import { GroupContext } from '../../../contexts/Group/GroupContext';
import { SiteMembersContext } from '../../../contexts/SiteMembers/SiteMembers';
import { useUser } from 'common/context/user/useUser';
import { debounce } from 'lodash';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { EVERYONE_MENTION_GUID } from '../../../../../config/constants';

const mentionsLimit = 5;

type MentionsConfig = {
  isCommentsOrigin?: boolean;
};

// TODO: [YO] fix types
export function useMentions(config: MentionsConfig = {}) {
  const [query, setQuery] = React.useState<string>();
  const [resolve, setResolve] = React.useState<Function>();

  const { group } = React.useContext(GroupContext);
  const { membersActions, membersQueryResponse } =
    React.useContext(SiteMembersContext);
  const { experiments } = useExperiments();

  const filterMembers = membersActions?.filterMembers || function () {};
  const debouncedFilterMembers = debounce(filterMembers, 100);

  const { userActions } = useUser();
  React.useEffect(() => {
    if (!resolve) {
      return;
    }

    if (query === membersQueryResponse.query) {
      resolve(
        membersQueryResponse.members.map((member) => {
          const everyoneMentionedInComments =
            config.isCommentsOrigin &&
            member.siteMemberId === EVERYONE_MENTION_GUID;
          return {
            name: member.name!.nick,
            avatar: member.imageUrl,
            id: everyoneMentionedInComments
              ? group.groupId
              : member.siteMemberId,
            ...(everyoneMentionedInComments && { type: 'GROUP' }),
          };
        }),
      );
    }
  }, [membersQueryResponse.query, query, resolve]);

  return {
    getMentions,
    onMentionClick,
    supportWhitespace: false,
  };

  function onMentionClick(mention: any) {
    userActions.openUserProfile(mention.id);
  }
  function getMentions(_query: any) {
    return new Promise((_resolve) => {
      setResolve(() => _resolve);
      setQuery(_query);
      debouncedFilterMembers(
        group,
        _query,
        experiments.enabled('specs.groups.IncreaseMentionsLimit')
          ? undefined
          : mentionsLimit,
      );
    });
  }
}
