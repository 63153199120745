import React from 'react';
import { TextButton, TextButtonProps } from 'wix-ui-tpa';

import { st, classes } from './CustomTextButton.st.css';

export const CustomTextButton: React.FC<TextButtonProps> = (props) => (
  <TextButton {...props} className={st(classes.root, props.className)} />
);

CustomTextButton.displayName = 'CustomTextButton';
