import { Box } from 'common/components/Box/Box';
import cls from 'classnames';
import { AuthorInfoSkeleton } from 'Group/Widget/Discussion/AuthorInfo';
import { placeholder, skeleton } from 'common/components/Skeleton';
import { Text, TextTypography } from 'wix-ui-tpa';
import React from 'react';

interface FeedItemSkeletonProps {
  className?: string;
}

export const FeedItemSkeleton: React.FC<FeedItemSkeletonProps> = (props) => {
  return (
    <Box className={cls(props.className)}>
      <div>
        <AuthorInfoSkeleton />
      </div>
      <br />
      <div className={cls(skeleton, placeholder)} />
      <br />
      <div>
        <Text typography={TextTypography.smallTitle} className={skeleton}>
          Lorem.
        </Text>
        &nbsp;
        <Text typography={TextTypography.smallTitle} className={skeleton}>
          Lorem.
        </Text>
        &nbsp;
        <Text typography={TextTypography.smallTitle} className={skeleton}>
          Lorem.
        </Text>
      </div>
    </Box>
  );
};
