import React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';
import { ButtonPriority } from 'wix-ui-tpa';

import { Dialog } from 'common/components/Dialog';
import { Button } from 'common/components/Button';

import { IControllerActions } from 'FeedWidget/types';

interface JoinGroupProps extends IControllerActions {
  isOpen: boolean;

  onClose(): void;
}

export const JoinGroup: React.FC<JoinGroupProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Dialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={t('groups-web.join.title')}
      caption={t('groups-web.join.text')}
      buttons={
        <Button
          fullWidth
          priority={ButtonPriority.primary}
          onClick={() => props.methods.group.redirectToGroup()}
        >
          {t('groups-web.join')}
        </Button>
      }
    />
  );
};

JoinGroup.displayName = 'JoinGroup';
