import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { ButtonProps, ButtonPriority, TextButtonPriority } from 'wix-ui-tpa';

import { Button } from '../../../../common/components/Button';
import { useUser } from '../../../../common/context/user/useUser';
import { useMemberFollow } from 'Group/contexts/MemberFollow/useMemberFollow';
import { CustomTextButton } from 'common/components/CustomTextButton';
import { useGroupBI } from 'Group/Widget/hooks/useGroupBI';

interface FollowButtonProps extends ButtonProps {
  siteMemberId: string;
  textButton?: boolean;
}

export const FollowButton: React.FC<FollowButtonProps> = (props) => {
  const { siteMemberId, textButton, ...restProps } = props;
  const { t } = useTranslation();
  const { myMember, userActions } = useUser();
  const { following, followActions } = useMemberFollow();
  const bi = useGroupBI();
  const isFollowing = following?.memberIds?.includes(siteMemberId);
  const isCurrentUser = myMember?.id === siteMemberId;

  const onClick = (e: any) => {
    e.stopPropagation();

    if (isCurrentUser) {
      userActions.openUserProfile(siteMemberId);
      return;
    }

    if (isFollowing) {
      followActions?.unfollowMember({ memberId: siteMemberId });
      bi.followMember({ site_member_id: siteMemberId, action: 'unfollow' });
      return;
    }

    followActions?.followMember({ memberId: siteMemberId });
    bi.followMember({ site_member_id: siteMemberId, action: 'follow' });
  };

  const followingLabel = isFollowing
    ? t('groups-web.following')
    : t('groups-web.follow');
  if (textButton) {
    return (
      <CustomTextButton
        {...restProps}
        onClick={onClick}
        priority={TextButtonPriority.primary}
      >
        {followingLabel}
      </CustomTextButton>
    );
  }
  return (
    <Button
      priority={
        isCurrentUser || isFollowing
          ? ButtonPriority.secondary
          : ButtonPriority.primary
      }
      onClick={onClick}
    >
      {isCurrentUser ? t('groups-web.my-profile') : followingLabel}
    </Button>
  );
};

FollowButton.displayName = 'FollowButton';
