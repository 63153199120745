import React from 'react';

import { Text } from 'wix-ui-tpa';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';

import {
  FeedItemActivityType,
  IFeedItemActivity,
} from '../../../types/IFeedItem';
import { UsersJoined } from './UsersJoined';
import { st, classes } from './ActivityStatus.st.css';

interface ActivityStatusProps {
  activity: IFeedItemActivity;
}

export const ActivityStatus: React.FC<ActivityStatusProps> = (props) => {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  switch (props.activity.activityType) {
    case FeedItemActivityType.DRAFT_JS: {
      if (props.activity.data.activity === 'event_added') {
        return (
          <Text className={st(classes.root, { mobile: isMobile })}>
            {t('groups-web.activity-post.event.added')}
          </Text>
        );
      }

      return null;
    }
    case FeedItemActivityType.GROUP_COVER_CHANGED: {
      const key = props.activity.data.hasCoverBefore
        ? 'groups-web.activity-post.cover-image.updated'
        : 'groups-web.activity-post.cover-image.added';

      return (
        <Text className={st(classes.root, { mobile: isMobile })}>{t(key)}</Text>
      );
    }
    case FeedItemActivityType.ABOUT_GROUP_CHANGED: {
      return (
        <Text className={st(classes.root, { mobile: isMobile })}>
          {t('groups-web.activity-post.about.changed')}
        </Text>
      );
    }
    case FeedItemActivityType.USERS_JOINED: {
      return <UsersJoined {...props.activity.data} />;
    }
    default: {
      return null;
    }
  }
};
