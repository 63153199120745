import React, { useMemo } from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { parseImageName } from '@wix/social-groups-api';

import {
  HeroImage,
  ImageResizeOptions,
  ImageLoadingBehaviorOptions,
} from 'wix-ui-tpa';

import { IFeedItem, IFeedItemActivityType } from 'FeedWidget/api/types';
import { RichContentViewer } from 'Group/Widget/RichContent/Viewer';
import { classes } from 'Group/Widget/Discussion/FeedItem/FeedItem.st.css';

interface FeedItemContentProps {
  item: IFeedItem;
}

export const FeedItemContent: React.FC<FeedItemContentProps> = ({ item }) => {
  const { isMobile } = useEnvironment();

  const content = useMemo(
    () => getFeedItemContent(item),
    [item.entity.body?.content, item.activity?.activityType],
  );

  if (
    item.activity?.activityType === IFeedItemActivityType.GROUP_COVER_CHANGED
  ) {
    return (
      <HeroImage
        fluid
        aspectRatio={isMobile ? 1 : 16 / 9}
        resize={ImageResizeOptions.cover}
        loadingBehavior={ImageLoadingBehaviorOptions.blur}
        className={classes.activityImage}
        src={parseImageName(item.activity.data.src)}
      />
    );
  }

  if (!content) {
    return null;
  }

  return (
    <RichContentViewer
      content={content}
      usage="FeedItem"
      contentId={item.feedItemId}
    />
  );
};

FeedItemContent.displayName = 'FeedItemContent';

function getFeedItemContent(item: IFeedItem) {
  let content: string;

  switch (item.activity?.activityType) {
    case IFeedItemActivityType.ABOUT_GROUP_CHANGED:
    case IFeedItemActivityType.DRAFT_JS:
      content = item.activity?.data.content;
      break;

    case IFeedItemActivityType.GROUP_COVER_CHANGED:
    case IFeedItemActivityType.USERS_JOINED:
      content = '';
      break;

    default:
      content = item.entity?.body?.content as string;
      break;
  }

  return content ? JSON.parse(content) : null;
}
