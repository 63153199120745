import { useMentions } from './useMentions';
import { usePolls } from './usePolls';
import { PluginTypes } from 'common/ContentEditor/plugins/pluginTypes';
import { useExperiments } from '@wix/yoshi-flow-editor';

export function useHelpers() {
  const mentions = useMentions();
  const polls = usePolls();
  const { experiments } = useExperiments();

  return {
    experiments,
    [PluginTypes.Mention]: mentions,
    [PluginTypes.Poll]: polls,
  };
}
