import React from 'react';
import { Text, TextButton } from 'wix-ui-tpa';
import { memberWrapper } from '@wix/social-groups-api';

import { st, classes } from '../ActivityStatus.st.css';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../../../contexts/SiteMembers/withSiteMembers';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../../contexts/TPAComponent/withTpaComponentsConfig';
import {
  WithTranslation,
  Trans,
  withTranslation,
} from '@wix/yoshi-flow-editor';
import { UserJoinedModal } from '../../../Modals/UsersJoinedModal/UsersJoinedModal';
import { compose } from '../../../../../../common/utils/compose';
import { IUsersJoinedActivity } from '../../../../types/IFeedItem';
import { IUserContext } from 'common/context/user/IUserContext';
import { withUser } from 'common/context/user/withUser';

interface UsersJoinedComponentProps
  extends UsersJoinedProps,
    WithSiteMembers,
    WithTpaComponentsConfigProps,
    WithTranslation,
    IUserContext {}

interface UsersJoinedProps extends IUsersJoinedActivity {}

interface UsersJoinedState {
  isUserJoinedModalOpened: boolean;
}

class UsersJoinedComponent extends React.Component<
  UsersJoinedComponentProps,
  UsersJoinedState
> {
  constructor(props: UsersJoinedComponentProps) {
    super(props);
    this.state = {
      isUserJoinedModalOpened: false,
    };
  }

  render() {
    const { userIds } = this.props;

    if (userIds.length === 1) {
      return this.renderUserJoined();
    }

    if (userIds.length === 2) {
      return this.render2UsersJoined();
    }

    return this.renderUsersJoined();
  }

  private renderUserJoined() {
    const { t, mobile } = this.props;

    return (
      <Text className={st(classes.root, { mobile } as any)}>
        {t('groups-web.activity-post.user.joined')}
      </Text>
    );
  }

  private render2UsersJoined() {
    const { t, siteMembersMap, mobile, userActions } = this.props;
    const otherUsers = this.getOtherUsers();
    const anotherUser = memberWrapper(siteMembersMap[otherUsers[0]]);
    return (
      <Trans
        i18nKey="groups-web.activity-post.2users.joined"
        values={{
          userName: anotherUser.name!.nick || t('groups-web.member.anonymous'),
        }}
        components={[
          <Text className={st(classes.root, { mobile } as any)} key="0">
            text with opacity
          </Text>,
          <TextButton
            className={st(classes.link, { mobile } as any)}
            key="1"
            onClick={() =>
              userActions.openUserProfile(anotherUser.siteMemberId!)
            }
          >
            user name
          </TextButton>,
        ]}
      />
    );
  }

  private renderUsersJoined() {
    const { userIds, siteMembersMap, mobile } = this.props;
    const { isUserJoinedModalOpened } = this.state;
    return (
      <>
        <Trans
          i18nKey="groups-web.activity-post.users.joined"
          values={{
            N: userIds.length - 1,
          }}
          components={[
            <Text className={st(classes.root, { mobile } as any)} key="0">
              text with opacity
            </Text>,
            <TextButton
              className={st(classes.link, { mobile } as any)}
              key="1"
              onClick={this.openUserJoinedModal}
            >
              N others
            </TextButton>,
          ]}
        />
        <UserJoinedModal
          members={userIds.map((id) => memberWrapper(siteMembersMap[id]))}
          isOpen={isUserJoinedModalOpened}
          onClose={this.closeUserJoinedModal}
        />
      </>
    );
  }

  private getOtherUsers() {
    const { userIds, authorUserId } = this.props;
    return userIds.filter((id) => id !== authorUserId);
  }

  private readonly openUserJoinedModal = () => {
    this.setState({ isUserJoinedModalOpened: true });
  };

  private readonly closeUserJoinedModal = () => {
    this.setState({ isUserJoinedModalOpened: false });
  };
}

const enhance = compose(
  withTranslation(),
  withSiteMembers,
  withTpaComponentsConfig,
  withUser,
);

export const UsersJoined = enhance(
  UsersJoinedComponent,
) as React.ComponentType<UsersJoinedProps>;
UsersJoined.displayName = 'UsersJoined';
