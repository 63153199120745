import React from 'react';

import { Spinner } from 'common/components/Spinner';
import { CommentsProps } from './Comments';

const Comments = React.lazy(() =>
  import(/* webpackChunkName: "comments" */ './Comments')
    .then((module) => ({ default: module.Comments }))
    .catch((e) => {
      console.log('Error in Comments lazy loading', e);
      return { default: () => null } as any;
    }),
);

export const FeedItemComments: React.FC<CommentsProps> = (props) => {
  const [isInBrowser, setIsInBrowser] = React.useState(false);

  React.useEffect(() => {
    setIsInBrowser(true);
  }, []);

  return isInBrowser ? (
    <React.Suspense fallback={<Spinner />}>
      <Comments {...props} />
    </React.Suspense>
  ) : (
    <Spinner />
  );
};
