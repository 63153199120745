import { createSelector } from 'reselect';

import { IStore } from 'FeedWidget/store/types';

export const feedSelector = createSelector(
  (state: IStore) => state.feed,
  (feed) => feed,
);

export const feedPermissionsSelector = createSelector(
  feedSelector,
  (feed) => feed.permissions,
);
